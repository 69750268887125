import React, { useState } from 'react';
import g1 from '../../../assets/Images/g1.jpg';
import g2 from '../../../assets/Images/g2.jpg';
import g3 from '../../../assets/Images/g3.jpg';
import g4 from '../../../assets/Images/g4.jpg';
import g5 from '../../../assets/Images/g5.jpg';
import g6 from '../../../assets/Images/g6.jpg';
import g7 from '../../../assets/Images/g7.jpg';
import g8 from '../../../assets/Images/g8.jpg';
import g9 from '../../../assets/Images/g9.jpg';
import v1 from '../../../assets/Images/v1.mp4';
import v2 from '../../../assets/Images/v2.mp4';
import v3 from '../../../assets/Images/v3.mp4';
import v4 from '../../../assets/Images/v4.mp4';
import v5 from '../../../assets/Images/v5.mp4';
import v6 from '../../../assets/Images/v6.mp4';
import vv1 from '../../../assets/Images/vv1.png';
import vv2 from '../../../assets/Images/vv2.png';
import vv3 from '../../../assets/Images/vv3.png';
import vv4 from '../../../assets/Images/vv4.png';
import vv5 from '../../../assets/Images/vv5.png';
import vv6 from '../../../assets/Images/vv6.png';

const Galleries = () => {
  const [activeTab, setActiveTab] = useState('pictures');

  const pictures = [
    {
      id: 1,
      img: g1,
      content: 'Solar Installation',
    },
    {
      id: 2,
      img: g2,
      content: 'Solar Installation',
    },
    {
      id: 3,
      img: g3,
      content: 'Solar Installation',
    },
    {
      id: 4,
      img: g4,
      content: 'Solar Installation',
    },
    {
      id: 5,
      img: g5,
      content: 'Access Control System',
    },
    {
      id: 6,
      img: g6,
      content: 'Solar Installation',
    },
    {
      id: 7,
      img: g7,
      content: 'Solar Installation',
    },
    {
      id: 8,
      img: g8,
      content: 'Earthing and thunder arrestor system',
    },
    {
      id: 9,
      img: g9,
      content: 'Solar Installation',
    },
  ];
  const videos = [
    {
      id: 1,
      vid: v1,
      poster: vv1,
      content: 'Access control system',
    },
    {
      id: 2,
      vid: v2,
      poster: vv2,
      content: 'Solar Installation',
    },
    {
      id: 3,
      vid: v3,
      poster: vv3,
      content: 'Solar Installation',
    },
    {
      id: 4,
      vid: v4,
      poster: vv4,
      content: 'Solar Installation',
    },
    {
      id: 5,
      vid: v5,
      poster: vv5,
      content: 'Building construction and maintenance',
    },
    {
      id: 6,
      vid: v6,
      poster: vv6,
      content: 'Electric fence system',
    },
  ];
  return (
    <section className='mt-[2.38rem] md:mt-[5.06rem] mac:max-w-[83rem] xl:max-w-[73rem] pro:max-w-[52rem] px-2 small:px-0 md:max-w-[45rem] max-w-[22rem] lg:max-w-[59rem] mx-auto'>
      <p className='text-[#002C54] text-center afacad leading-normal font-normal text-[1.125rem] md:text-[1.375rem]'>
        Explore some of our projects, See the results of our expertise in
        renewable energy, smart solutions and sustainable construction
      </p>

      <div className='flex justify-center'>
        <div className='rounded-full bg-[#6DC3FD] mt-[2.81rem] md:mt-[4.37rem] flex justify-between items-center h-[3.625rem] md:h-[4.1875rem] w-[18rem] md:w-[20.9375rem] p-3'>
          <button
            className={`flex-1 text-center afacad md:text-lg lg:text-[1.5rem] font-semibold leading-normal rounded-full ${
              activeTab === 'pictures'
                ? 'bg-[#0171BB] text-white py-[10px] px-[2rem] md:px-[2.3rem] rounded-full'
                : 'bg-transparent text-white'
            }`}
            onClick={() => setActiveTab('pictures')}
          >
            Pictures
          </button>
          {/* Videos Tab */}
          <button
            className={`flex-1 text-center afacad md:text-lg lg:text-[1.5rem] font-semibold leading-normal rounded-full ${
              activeTab === 'videos'
                ? 'bg-[#0171BB] text-white py-[10px] px-[2rem] md:px-[2.3rem] rounded-full'
                : 'bg-transparent text-white'
            }`}
            onClick={() => setActiveTab('videos')}
          >
            Videos
          </button>
        </div>
      </div>
      {/* Conditionally render content based on the active tab */}
      <div className='mt-[4.75rem] lg:mt-[7.06rem]'>
        {activeTab === 'pictures' && (
          <div className='flex flex-wrap gap-5 lg:gap-7'>
            {pictures.map((gal, index) => (
              <div
                className={`${
                  index === 2 || index === 5 || index === 8
                    ? '!basis-[100%] md:!basis-[30.33%]'
                    : ' md:basis-[30.33%]'
                } ${
                  index === 1 || index === 4 || index === 7
                    ? 'basis-[45.33%] md:basis-[31.7%] lg:basis-[33%] xl:basis-[34%] mac:basis-[35%]'
                    : 'basis-[45.33%] md:basis-[30.33%]' // Customize the width for specific images
                }`}
              >
                <img
                  src={gal.img}
                  alt='GalleryImage'
                  className={`${
                    index === 1 || index === 2
                      ? 'h-[9.625rem] small:h-[11.025rem] md:h-[15.225rem] lg:h-[19.825rem] xl:h-[24.825rem] mac:h-[28.125rem]'
                      : ''
                  } ${
                    index === 3 || index === 4 || index === 5
                      ? 'h-[13rem] md:h-[13.225rem] lg:h-[19.825rem] xl:h-[27.025rem]'
                      : ''
                  } ${
                    index === 7 || index === 8
                      ? 'h-[9.625rem] small:h-[11.025rem] md:h-[15.225rem] lg:h-[19.825rem] xl:h-[24.825rem] mac:h-[28.225rem]'
                      : ''
                  } ${
                    index === 5
                      ? 'h-[14.22rem] md:h-[13.225rem] lg:h-[19.825rem] xl:h-[27.025rem]'
                      : ''
                  } object-cover w-full`}
                />
                <p className='text-[#002C54] afacad pt-[0.8rem] text-sm md:text-base lg:text-lg font-normal leading-normal'>
                  {gal.content}
                </p>
              </div>
            ))}
          </div>
        )}

        <div className='mt-[4.75rem] lg:mt-[7.06rem]'>
          {activeTab === 'videos' && (
            <div className='grid grid-cols-1 md:grid-cols-2 gap-7 lg:gap-12 '>
              {videos.map((vids) => (
                <div key={vids.id} className=''>
                  <video
                    src={vids.vid} // Ensure the correct video source is provided
                    controls // Enables play/pause controls
                    poster={vids.poster}
                    className={`w-full rounded-md h-[28rem] md:h-[35rem] lg:h-[45rem] object-cover`} // Ensure the video fits its container
                  />
                  <p className='text-[#002C54] afacad pt-[0.8rem] text-sm md:text-base lg:text-lg font-normal leading-normal'>
                    {vids.content}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Galleries;
