import React from 'react';
import ser1 from '../../../assets/Svgs/ser1.svg';
import { Link } from 'react-router-dom';
import ser2 from '../../../assets/Images/ser2.jpg';
import ser3 from '../../../assets/Images/ser3.jpg';
import ser4 from '../../../assets/Images/ser4.jpg';
import ser5 from '../../../assets/Images/ser5.jpg';
import ser6 from '../../../assets/Images/ser6.jpg';
import ser7 from '../../../assets/Images/ser7.jpg';
import ser8 from '../../../assets/Images/ser8.jpg';
const CoreServices = () => {
  const services = [
    {
      id: 1,
      title: 'Renewable Solar Solutions',
      content:
        'At Nasmaj Hubs Services Ltd, we specialize in providing clean, sustainable solar energy solutions to homes and businesses. Our solar panel installations are designed to maximize energy efficiency, reduce costs, and lower environmental impact. Whether its a small-scale residential setup or a large commercial installation, we bring innovation and expertise to every project.',
      button: 'Contact us',
      img: ser1,
    },
    {
      id: 2,
      title: 'Smart Home & Automation',
      content:
        'Imagine a home that works for you. Our smart home solutions make your space more convenient, secure, and energy-efficient. With integrated control systems for lighting, climate, security, and entertainment, we turn your house into a smart hub that can be controlled via smartphone, tablet, or voice command.',
      button: 'Contact us',
      img: ser2,
    },
    {
      id: 3,
      title: 'Building Maintenance & Construction',
      content:
        'Nasmaj Hubs Services Ltd offers a wide range of building maintenance and construction services, from small repairs to full-scale construction projects. Whether you are upgrading an existing structure or building from the ground up, our team delivers high-quality results on time and within budget.',
      button: 'Contact us',
      img: ser3,
    },
    {
      id: 4,
      title: 'Surveillance & Security Solutions',
      content:
        'Ensuring the safety of your property and loved ones is a top priority. Nasmaj Hubs Services Ltd offers state-of-the-art surveillance and security systems tailored to your specific needs. From residential homes to large business premises, we provide CCTV, alarm systems, and integrated security solutions to give you 24/7 peace of mind.',
      button: 'Contact us',
      img: ser4,
    },
    {
      id: 5,
      title: 'Fire Alarm System',
      content:
        'Nasmaj Hubs Services Ltd offers comprehensive fire alarm system design, installation, and maintenance services. Our systems are equipped with cutting-edge technology to provide early detection and rapid response, ensuring that your building complies with all safety regulations and provides maximum protection in the event of a fire.',
      button: 'Contact us',
      img: ser5,
    },
    {
      id: 6,
      title: 'Network Infrastructure',
      content:
        'In today’s digital world, robust network infrastructure is the backbone of any successful business. Nasmaj Hubs Services Ltd provides end-to-end network infrastructure solutions, including structured cabling, network design, installation, and maintenance services. Our team ensures that your network is optimized for speed, security, and reliability, supporting everything from small offices to large enterprise environments.',
      button: 'Contact us',
      img: ser6,
    },
    {
      id: 7,
      title: 'Street Lighting Installation',
      content:
        'We deliver energy-efficient and sustainable street lighting solutions for municipalities, residential complexes, and commercial properties. Our street lighting installations utilize advanced LED technology to reduce energy consumption and enhance visibility and safety. Whether for urban streets or suburban developments, our lighting solutions are tailored to meet the specific needs of each project.',
      button: 'Contact us',
      img: ser7,
    },
    {
      id: 8,
      title: 'General Contract',
      content:
        'As a full-service general contractor, Nasmaj Hubs Services Ltd oversees every aspect of your project from start to finish. Our team manages all phases of construction and renovation, ensuring that every detail aligns with your vision and specifications. We are committed to delivering exceptional craftsmanship and client satisfaction.',
      button: 'Contact us',
      img: ser8,
    },
  ];
  return (
    <section className='mt-[2.88rem] md:mt-[4.44rem] lg:mt-[6.44rem] mac:max-w-[83rem] px-2 small:px-0 mx-auto xl:max-w-[72rem] pro:max-w-[52rem] md:max-w-[45rem] small:max-w-[22rem]  lg:max-w-[59rem]'>
      <h4 className='afacad text-[17px] xl:text-[1.4rem] font-normal leading-normal text-[#002C54] flex justify-center'>
        At Nasmaj Hubs Services Ltd, we specialize in providing clean,
        sustainable solar energy solutions to homes and businesses. Whether it's
        a
        <br className='hidden lg:block' /> small-scale residential setup or a
        large commercial installation, we bring innovation and expertise to
        every project.
      </h4>

      <div className='mt-[4.61rem] md:mt-[4.51rem] lg:mt-[6.51rem] xl:mt-[8.51rem] space-y-[4.5rem] md:space-y-[4.98rem] lg:space-y-[6.38rem] xl:space-y-[9.38rem] '>
        {services.map((serv, index) => (
          <article
            className={`flex flex-col gap-[2rem] md:flex-row md:justify-around ${
              index % 2 !== 0 ? 'md:flex-row-reverse md:justify-evenly' : ''
            }`}
          >
            <div className='lg:w-[20.375rem] xl:w-[30.375rem]'>
              <h5
                className={`pb-[0.75rem] md:pb-[1rem] text-[#002C54] afacad leading-normal font-medium text-[1.375rem] md:text-base lg:text-[1.5rem] xl:text-[1.625rem] border-b-2 border-[#00B5E8] lg:w-[37.09375rem] xl:w-[48.09375rem]`}
              >
                {serv.title}
              </h5>
              <p className='md:w-[18.375rem] lg:w-[23.375rem] xl:w-[30.375rem] pt-[1.25rem] md:pt-[2.65rem] text-[#002C54] afacad font-normal leading-normal md:text-sm lg:text-base xl:text-[1.1875rem]'>
                {serv.content}
              </p>
              <Link to='/contact'>
                <button className='px-[2.3rem] hidden md:flex mt-[1.59rem] py-[0.75rem] text-[#002C54] text-lg lg:text-[1.5rem] leading-normal font-semibold afacad bg-[#FFDF53] rounded-full'>
                  {serv.button}
                </button>

                <p className='mt-[1.29rem] afacad  md:hidden font-medium leading-normal underline text-[#00AAFA] text-base'>
                  Get Started
                </p>
              </Link>
            </div>
            <img
              className='mac:w-[38rem] lg:h-auto md:w-[21rem] xl:w-[37rem] lg:w-[27rem] lg:-mt-5 xl:-mt-0'
              src={serv.img}
              alt={serv.title}
            />
          </article>
        ))}
      </div>
    </section>
  );
};

export default CoreServices;
