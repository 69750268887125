import React from 'react';
import background from '../../assets/Svgs/background.svg';

const Header = ({ text, background }) => {
  return (
    <section className='relative pt-10 md:pt-16 lg:pt-10'>
      <img
        src={background}
        alt=''
        className='object-cover w-full h-[22.9375rem] md:h-[30rem] lg:h-[40rem]'
      />
      <div className='small:w-[20.4375rem] w-[18.4375rem] h-[9.5rem] md:w-[35.4375rem] lg:w-[50.4375rem] md:h-[17.0625rem] absolute inset-0 mx-auto my-auto flex justify-center rounded-[1.25rem] bg-white/30 backdrop-blur-[15px] border-white/20 border-2'>
        <h2 className='text-[#002C54] my-auto font-bold leading-[3.8125rem] text-[2.5rem] md:text-[3.8125rem] afacad'>
          {text}
        </h2>
      </div>
    </section>
  );
};

export default Header;
