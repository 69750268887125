import React, { useEffect } from 'react';
import Header from '../Header';
import services from '../../../assets/Svgs/coreServices.svg';
import CoreServices from './CoreServices';

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <section>
      <Header text='Core Services' background={services} />
      <CoreServices />
    </section>
  );
};

export default Services;
