import React from 'react';
import footer from '../../assets/Images/footer.jpg';
import footer2 from '../../assets/Images/footer2.jpg';
import logo from '../../assets/Images/logo2.png';
import call from '../../assets/Svgs/call.svg';
import message from '../../assets/Svgs/message.svg';
import locations from '../../assets/Svgs/location.svg';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  return (
    <section
      className={`${
        location.pathname === '/about' ? 'mt-0' : 'lg:mt-[6rem] '
      } mt-[3rem] `}
    >
      <div className='relative max-w-[95rem] mx-auto'>
        <img src={footer} alt='footer' className='hidden md:block' />
        <img src={footer2} alt='footer' className=' md:hidden block' />
        <div className='w-[12.0625rem] h-[9.4375rem] small:w-[13.0625rem] small:h-[10.4375rem] md:w-[28.5625rem] md:h-[11.4375rem] lg:w-[36.5625rem] mx-3 md:mx-16 absolute inset-0 lg:h-[14.4375rem] border-2 border-white/20 rounded-lg bg-white/10 backdrop-blur-[15px] my-auto p-5 small:p-7 lg:p-12'>
          <h1 className='md:text-[2.8125rem] text-3xl small:text-[2rem] lg:text-[3.8125rem] afacad font-bold text-[#002C54] leading-6 small:leading-8 md:leading-[3.8125rem]'>
            Let’s work together
          </h1>

          <button className='bg-[#FFDF53] mt-[1.5rem] md:mt-[1rem] text-xs lg:mt-[2.06rem] py-2 font-semibold md:text-sm lg:text-lg leading-normal inter text-[#002C54] rounded-full md:py-[1rem] lg:py-[1.12rem] px-[2.5rem]'>
            <Link to='/contact'> Contact Us</Link>
          </button>
        </div>
      </div>
      <footer className='bg-[#002C54] h-[41rem] small:h-[37.3875rem] md:h-[24.1875rem] lg:h-[28.1875rem] pt-7 md:pt-16 lg:pt-24'>
        <div className='mac:max-w-[83rem] xl:max-w-[73rem] pro:max-w-[52rem] md:max-w-[45rem] max-w-[22rem] lg:max-w-[59rem] mx-auto flex flex-col px-2 small:px-0 md:flex-row md:justify-between lg:justify-around'>
          <div className='lg:w-full'>
            <img
              src={logo}
              alt='logo'
              className='object-contain h-[2.50538rem] w-[11.1875rem] mb-[0.8rem] md:mb-[2.12rem]'
            />
            <p className='text-white afacad w-[18rem] md:w-[12rem] lg:w-full text-[1.125rem] font-medium leading-[1.5625rem]'>
              Nasmaj Hubs Services Ltd provides provides{' '}
              <br className='hidden lg:block' />
              innovative, eco-friendly energy solutions for
              <br className='hidden lg:block' />
              homes and businesses.
            </p>
          </div>
          <div className='pt-[3rem] md:pt-0 space-y-[0.8rem] md:space-y-[1.5rem] lg:w-full '>
            <h3 className='md:text-white text-[#FFDF53] afacad text-[1.25rem] font-semibold leading-normal'>
              Quick links
            </h3>
            <span className='grid grid-cols-2 md:grid-cols-none md:space-y-[1.5rem]'>
              <p className='font-normal leading-normal text-[1.125rem] afacad text-white'>
                <Link to='/'> Home </Link>
              </p>
              <p className='font-normal leading-normal text-[1.125rem] afacad text-white'>
                <Link to='/services'>Services</Link>
              </p>
              <p className='font-normal leading-normal text-[1.125rem] afacad text-white'>
                <Link to='/about'> About Us</Link>
              </p>
              <p className='font-normal leading-normal text-[1.125rem] afacad text-white'>
                <Link to='/gallery'> Gallery</Link>
              </p>
              <p className='font-normal leading-normal hidden md:block text-[1.125rem] afacad text-white'>
                <Link to='/contact'> Contact</Link>
              </p>
            </span>
          </div>
          <div className='pt-[3rem] md:pt-0 space-y-[0.8rem] md:space-y-[1.5rem] md:w-[18rem] lg:w-full'>
            <h3 className='md:text-white text-[#FFDF53] afacad text-[1.25rem] font-semibold leading-normal '>
              Contact
            </h3>

            <span className='flex gap-3 md:gap-7 items-center'>
              <img src={locations} alt='location' />
              <p className='font-normal leading-normal text-[1.125rem] afacad text-white'>
                2, Akinremi Street, Anifowoshe Junction.
                <br className='hidden lg:block' /> Computer Village. Ikeja
              </p>
            </span>
            <span className='flex gap-3 md:gap-7 items-center'>
              <img src={message} alt='' />

              <p className='font-normal leading-normal text-[1.125rem] afacad text-white'>
                info@nasmajhubsservices.com
              </p>
            </span>
            <span className='flex gap-3 md:gap-7 items-center'>
              <img src={call} alt='' />
              <span>
                <p className='font-normal leading-normal text-[1.125rem] afacad text-white'>
                  +234 706 122 5320
                </p>
                <p className='font-normal leading-normal text-[1.125rem] afacad text-white'>
                  +234 805 808 5008
                </p>
              </span>
            </span>
          </div>
        </div>
      </footer>
      <div className='border-t-[1px] border-white py-7 bg-[#002C54] h-24'>
        <p className='font-medium leading-normal text-center text-xs text-white'>
          Copyright@2024. All right reserved
        </p>
      </div>
    </section>
  );
};

export default Footer;
