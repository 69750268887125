import React, { useEffect } from 'react';
import Header from '../Header';
import Who from './Who';
import Why from './Why';
import Mission from './Mission';
import Meet from './Meet';
import About from '../../../assets/Svgs/Abouts.svg';

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div>
      <Header text='About Us' background={About} />
      <Who />
      <Why />
      <Mission />
      <Meet />
    </div>
  );
};

export default AboutPage;
