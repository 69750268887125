import React from 'react';
import Ceo from '../../../assets/Svgs/Ceo.svg';
import light from '../../../assets/Svgs/light.svg';

const Meet = () => {
  return (
    <section className='mt-[3.44rem] md:mt-[4.44rem] lg:mt-[6.44rem]'>
      <article className='lg:max-w-[83rem] pro:max-w-[52rem] md:max-w-[45rem] small:max-w-[22rem] px-2 small:px-0 mx-auto flex flex-col gap-[2.25rem] md:gap-0 md:flex-row md:justify-between lg:justify-evenly'>
        <div>
          <h2 className='text-[#002C54] afacad leading-normal text-[2rem] md:text-[3rem] md:leading-[5.625rem] font-bold'>
            Meet the CEO
          </h2>

          <p className='w-full md:w-[21rem] lg:w-[32.25rem] text-[#002C54] afacad text-base md:text-sm lg:text-xl font-normal leading-normal'>
            Abdulmajeed Nasir Sheidu, the visionary behind Nasmaj Hubs Services
            Ltd, brings a wealth of experience and a passion for innovation to
            the company. With over 5 years of expertise in renewable energy,
            smart technologies, and construction, he has been instrumental in
            shaping Nasmaj Hubs Services Ltd into the dynamic and
            forward-thinking company it is today.
            <br />
            <br /> Under his leadership, Nasmaj Hubs Services Ltd has expanded
            its footprint across multiple industries, including solar energy,
            smart home automation, and building maintenance. Mr Abdulmajeed is
            committed to driving sustainability and delivering cutting-edge
            solutions that meet the evolving needs of modern homes and
            businesses. He believe in a customer-first approach, ensuring that
            each project is tailored to exceed client expectations while
            contributing to a greener, smarter future.
          </p>
        </div>
        <img
          src={Ceo}
          alt=''
          className='lg:w-auto lg:h-auto md:w-[22rem] h-[25rem]'
        />
      </article>
      <img
        src={light}
        alt=''
        className='md:-rotate-[245deg]  lg:-rotate-[260deg] xl:-rotate-[240deg] hidden xl:ml-8 md:flex -mt-1 lg:-mt-3 xl:-mt-32 xl:w-auto md:w-[5rem] md:h-[8rem] lg:w-[8rem] lg:h-[8rem] xl:h-[12rem]'
      />
    </section>
  );
};

export default Meet;
