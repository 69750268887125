import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutPage from './Routers/About/AboutPage';
// import '../App';
import Home from './Home';
import Contact from './Routers/Contact/Contact';
import Layout from './Layout';
import Services from './Routers/Services/Services';
import Gallery from './Routers/Gallery/Gallery';

const RouterComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route
          path='/about'
          element={
            <Layout>
              <AboutPage />
            </Layout>
          }
        />
        <Route
          path='/contact'
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
        <Route
          path='/services'
          element={
            <Layout>
              <Services />
            </Layout>
          }
        />
        <Route
          path='/gallery'
          element={
            <Layout>
              <Gallery />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};

export default RouterComponent;
