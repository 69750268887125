import React from 'react';

const Mission = () => {
  return (
    <section className='max-w-[98rem] mx-auto flex flex-col gap-[1.5rem] md:gap-0 md:flex-row md:justify-between mt-[4.2rem] md:mt-[3rem] lg:mt-[2.69rem]'>
      <div className='md:w-[23.375rem] lg:w-[31.375rem] xl:w-[39.375rem] mac:w-[44.375rem] px-[1.37rem] md:px-0 md:pl-[3.44rem] lg:pl-[2.44rem] xl:pl-[7.44rem] mac:pl-[8.44rem] md:py-[2rem] xl:py-[3rem] h-[20.25rem] md:h-[25.6875rem] bg-[#002C54] rounded-tr-2xl rounded-br-2xl md:rounded-tr-lg md:rounded-br-lg'>
        <h2 className='text-[#FFDF53] flex gap-3 items-center afacad leading-[5.625rem] text-[2rem] md:text-[2.5rem] xl:text-[3rem] font-bold'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='29'
            height='30'
            viewBox='0 0 29 30'
            fill='none'
          >
            <g clip-path='url(#clip0_4066_1155)'>
              <path
                d='M28.3738 28.3498L21.8538 16.4998C21.7438 16.3098 21.5438 16.1998 21.3238 16.1898C21.1038 16.1898 20.9038 16.3298 20.8038 16.5198L18.5438 21.1898L11.8538 8.91977V6.69977H18.3738C18.6138 6.69977 18.8338 6.55977 18.9238 6.32977C19.0138 6.10977 18.9638 5.84977 18.7938 5.67977L16.8438 3.72977L18.7938 1.77977C18.9638 1.60977 19.0138 1.35977 18.9238 1.12977C18.8338 0.909766 18.6138 0.759766 18.3738 0.759766H11.2638C10.9338 0.759766 10.6738 1.02977 10.6738 1.34977V8.89977L0.0737947 28.3498C-0.0262053 28.5298 -0.0262053 28.7598 0.0837947 28.9398C0.193795 29.1198 0.383795 29.2298 0.593795 29.2298H27.8538C28.0638 29.2298 28.2538 29.1198 28.3638 28.9398C28.4738 28.7598 28.4738 28.5398 28.3638 28.3498H28.3738ZM11.2638 10.3098L14.2938 15.8698L13.1038 17.0598L11.7338 15.2398C11.5138 14.9398 11.0038 14.9398 10.7838 15.2398L9.41379 17.0598L8.22379 15.8698L11.2538 10.3098H11.2638Z'
                fill='#FFDF53'
              />
            </g>
            <defs>
              <clipPath id='clip0_4066_1155'>
                <rect
                  width='28.44'
                  height='28.44'
                  fill='white'
                  transform='translate(0 0.779297)'
                />
              </clipPath>
            </defs>
          </svg>
          Our Mission
        </h2>
        <p className='md:w-[18rem] text-sm small:text-base w-full lg:w-[25.125rem] xl:w-[29.125rem] md:text-[15px] text-white afacad lg:text-lg xl:text-[1.25rem] font-normal leading-normal'>
          To be a global leader in providing sustainable, innovative, and
          technologically advanced solutions that improve the quality of life,
          enhance security, and contribute to a greener planet. We aspire to
          transform how people live and work by offering integrated solutions in
          renewable energy, smart automation, and construction, all while
          promoting environmental responsibility.
        </p>
      </div>
      <div className='w-full md:w-[23.375rem] lg:w-[31.375rem] xl:w-[39.375rem] px-[1.37rem] md:px-0 mac:w-[44.375rem] h-[21.85rem] md:h-[25.6875rem] md:pl-[3.44rem] lg:pl-[2.44rem] xl:pl-[7.44rem] mac:pl-[8.44rem] md:py-[2rem] xl:py-[3rem] bg-[#002C54] rounded-tl-2xl rounded-bl-2xl md:rounded-tl-lg md:rounded-bl-lg'>
        <h2 className='text-[#FFDF53] flex gap-3 items-center afacad leading-[5.625rem] text-[2rem] md:text-[2.5rem] xl:text-[3rem] font-bold'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='35'
            height='30'
            viewBox='0 0 35 30'
            fill='none'
          >
            <g clip-path='url(#clip0_4066_1159)'>
              <path
                d='M33.63 18.6493L28.13 4.0193C27.4 2.0893 25.53 0.789297 23.47 0.789297C21.41 0.789297 19.54 2.0893 18.81 4.0193C18.6 4.5793 18.49 5.1693 18.49 5.7693V12.1693H15.65V5.7693C15.64 3.0093 13.41 0.779297 10.67 0.779297C8.61 0.779297 6.73 2.0793 6.01 4.0093L0.5 18.6393C0.17 19.5193 0 20.4493 0 21.3993C0 25.7093 3.51 29.2193 7.82 29.2193C12.13 29.2193 15.64 25.7093 15.64 21.3993V20.6893H18.48V21.3993C18.48 25.7093 21.99 29.2193 26.3 29.2193C30.61 29.2193 34.12 25.7093 34.12 21.3993C34.12 20.4493 33.95 19.5293 33.62 18.6493H33.63ZM7.82 26.3793C5.08 26.3793 2.84 24.1493 2.84 21.3993C2.84 20.7993 2.95 20.2093 3.16 19.6493C3.89 17.7193 5.76 16.4193 7.82 16.4193C10.56 16.4193 12.8 18.6493 12.8 21.3993C12.8 24.1493 10.57 26.3793 7.82 26.3793ZM26.31 26.3793C23.57 26.3793 21.33 24.1493 21.33 21.3993C21.33 18.6493 23.56 16.4193 26.31 16.4193C28.37 16.4193 30.25 17.7193 30.97 19.6493C31.18 20.2093 31.29 20.7993 31.29 21.3993C31.29 24.1393 29.06 26.3793 26.31 26.3793Z'
                fill='#FFDF53'
              />
            </g>
            <defs>
              <clipPath id='clip0_4066_1159'>
                <rect
                  width='34.13'
                  height='28.44'
                  fill='white'
                  transform='translate(0 0.779297)'
                />
              </clipPath>
            </defs>
          </svg>{' '}
          Our Vision
        </h2>

        <p className='md:w-[18rem] text-sm small:text-base w-full lg:w-[25.125rem] xl:w-[29.125rem] md:text-[15px] text-white afacad lg:text-lg xl:text-[1.25rem] font-normal leading-normal'>
          To deliver state-of-the-art, tailored services in renewable energy,
          security, smart home automation, and construction. To helping our
          clients optimize their resources and improve their operational
          efficiency through the power of innovation and sustainability. By
          prioritizing customer satisfaction, quality craftsmanship, and
          responsible business practices, we aim to create long-lasting value
          for our clients, partners, and communities.
        </p>
      </div>
    </section>
  );
};

export default Mission;
