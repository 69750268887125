import React, { useState } from 'react';
import logo from '../../assets/Images/logo2.png';
import { Twirl as Hamburger } from 'hamburger-react';
import { Link, useLocation } from 'react-router-dom';

const Nav = () => {
  const [nav, setNav] = useState(false);
  const location = useLocation();
  return (
    <section className='bg-[#002C54] py-3 md:py-7 fixed z-50 w-full'>
      <div className='flex justify-between items-center mac:max-w-[83rem] px-2 small:px-0 mx-auto xl:max-w-[72rem] pro:max-w-[52rem] md:max-w-[45rem] max-w-[22.5rem]  lg:max-w-[59rem]'>
        <img
          src={logo}
          alt='logo'
          className='object-contain h-[2.50538rem] w-[9.1875rem] md:w-[11.1875rem]'
        />

        <div className=' text-white roboto hidden lg:flex'>
          <ul className='flex space-x-[3.5rem]'>
            <li
              className={`hover:text-[#FFDF53] ${
                location.pathname === '/'
                  ? 'text-[#FFDF53]'
                  : 'hover:text-[#FFDF53] text-white'
              }`}
            >
              <Link to='/'>Home</Link>
            </li>
            <li
              className={`hover:text-[#FFDF53] ${
                location.pathname === '/about'
                  ? 'text-[#FFDF53]'
                  : 'hover:text-[#FFDF53] text-white'
              }`}
            >
              <Link to='/about'>About Us</Link>
            </li>
            <li
              className={`hover:text-[#FFDF53] ${
                location.pathname === '/services'
                  ? 'text-[#FFDF53]'
                  : 'hover:text-[#FFDF53] text-white'
              }`}
            >
              <Link to='/services'>Services</Link>
            </li>

            <li
              className={`hover:text-[#FFDF53] ${
                location.pathname === '/gallery'
                  ? 'text-[#FFDF53]'
                  : 'hover:text-[#FFDF53] text-white'
              }`}
            >
            <Link to='/gallery'>  Gallery</Link>
            </li>

            <li
              className={`hover:text-[#FFDF53] ${
                location.pathname === '/contact'
                  ? 'text-[#FFDF53]'
                  : 'hover:text-[#FFDF53] text-white'
              }`}
            >
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </div>
        <span className='lg:hidden'>
          <Hamburger
            direction='twirl'
            size={25}
            color='white'
            className='burger-menu'
            toggled={nav}
            toggle={() => setNav(!nav)}
          />
        </span>
        {nav && (
          <div className='fixed top-12 md:top-24 left-0 w-full z-20'>
            <div className='w-full bg-[#002C54] h-screen pt-5 px-6'>
              <ul className='flex-col flex pt-[47px] space-y-[2rem]'>
                <li
                  className={`hover:text-[#FFDF53] ${
                    location.pathname === '/'
                      ? 'text-[#FFDF53]'
                      : 'hover:text-[#FFDF53] text-white'
                  }`}
                >
                  <Link to='/' onClick={() => setNav(false)}>
                    Home
                  </Link>
                </li>
                <li
                  className={`hover:text-[#FFDF53] ${
                    location.pathname === '/about'
                      ? 'text-[#FFDF53]'
                      : 'hover:text-[#FFDF53] text-white'
                  }`}
                >
                  <Link to='/about' onClick={() => setNav(false)}>
                    {' '}
                    About Us
                  </Link>
                </li>
                <li
                  className={`hover:text-[#FFDF53] ${
                    location.pathname === '/services'
                      ? 'text-[#FFDF53]'
                      : 'hover:text-[#FFDF53] text-white'
                  }`}
                >
                  <Link to='/services' onClick={() => setNav(false)}>
                    Services
                  </Link>
                </li>
                <li
                  className={`hover:text-[#FFDF53] ${
                    location.pathname === '/gallery'
                      ? 'text-[#FFDF53]'
                      : 'hover:text-[#FFDF53] text-white'
                  }`}
                >
                  <Link to='/gallery' onClick={() => setNav(false)}>
                    Gallery
                  </Link>
                </li>
                <li
                  className={`hover:text-[#FFDF53] ${
                    location.pathname === '/contact'
                      ? 'text-[#FFDF53]'
                      : 'hover:text-[#FFDF53] text-white'
                  }`}
                >
                  <Link to='/contact' onClick={() => setNav(false)}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Nav;
