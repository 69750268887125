import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';

const Testimonials = () => {
  return (
    <section className='bg-[#002C54] h-[25rem] small:h-[27rem] md:h-[35rem] lg:h-[40rem] md:mt-[5rem] xl:mt-[7rem] py-[1.2rem] md:py-[3rem] lg:py-[5rem]'>
      <h2 className=' text-[#FFDF53] text-center leading-normal small:leading-[5.625rem] text-[1.5rem] small:text-[2rem] md:text-[3rem] font-bold afacad'>
        What our clients are saying
      </h2>

      <div className='mac:max-w-[83rem] mx-auto xl:max-w-[73rem] pro:max-w-[52rem] md:max-w-[45rem] px-1 lg:max-w-[59rem] mt-[2rem] small:mt-[1.5rem] md:mt-[3.5rem]'>
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination, Navigation]}
          navigation={true}
          className='mySwiper'
        >
          <SwiperSlide>
            <div className='w-[17.3125rem] h-[14.75rem] small:w-[18.3125rem] small:h-[13.75rem] md:w-[28.0625rem] lg:w-[41.0625rem] md:h-[16.875rem] mx-auto rounded-[1.25rem] px-[0.95rem] md:px-[3.25rem] lg:px-[6.25rem] pt-[2rem] lg:pt-[3rem] xl:pt-[3.31rem] backdrop-[15px] bg-[#0C5C93]/50 border-2 border-[#0C5C9380]'>
              <p className='text-white text-sm small:text-[15.5px] small:leading-[1.25rem] md:leading-[1.75rem] md:text-left text-center pb-[0.63rem] md:pb-[2.69rem] md:text-lg font-normal afacad '>
                "Nasmaj Hubs Services Ltd transformed our home with their smart
                automation solutions. We can now control our entire house from
                our phones! Their team was professional and quick to implement
                the system."
              </p>

              <h5 className='text-white text-lg text-center leading-normal font-medium afacad'>
                Adeyemi Samson, Residential Customer
              </h5>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='w-[17.3125rem] h-[14.75rem] small:w-[18.3125rem] small:h-[13.75rem] md:w-[28.0625rem] lg:w-[41.0625rem] md:h-[16.875rem] mx-auto rounded-[1.25rem] px-[0.95rem] md:px-[3.25rem] lg:px-[6.25rem] pt-[2rem] lg:pt-[3rem] xl:pt-[3.31rem] backdrop-[15px] bg-[#0C5C93]/50 border-2 border-[#0C5C9380]'>
              <p className='text-white text-sm small:text-[15.5px] small:leading-[1.25rem] md:leading-[1.75rem] md:text-left text-center pb-[0.63rem] md:pb-[2.69rem] md:text-lg font-normal afacad '>
                "Nasmaj Hubs Services Ltd transformed our home with their smart
                automation solutions. We can now control our entire house from
                our phones! Their team was professional and quick to implement
                the system."
              </p>

              <h5 className='text-white text-lg text-center leading-normal font-medium afacad'>
                Fola Adams, Residential
                <br className='md:hidden' /> Customer
              </h5>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='w-[17.3125rem] h-[14.75rem] small:w-[18.3125rem] small:h-[13.75rem] md:w-[28.0625rem] lg:w-[41.0625rem] md:h-[16.875rem] mx-auto rounded-[1.25rem] px-[0.95rem] md:px-[3.25rem] lg:px-[6.25rem] pt-[2rem] lg:pt-[3rem] xl:pt-[3.31rem] backdrop-[15px] bg-[#0C5C93]/50 border-2 border-[#0C5C9380]'>
              <p className='text-white text-sm small:text-[15.5px] small:leading-[1.25rem] md:leading-[1.75rem] md:text-left text-center pb-[0.63rem] md:pb-[2.69rem] md:text-lg font-normal afacad '>
                "Nasmaj Hubs Services Ltd transformed our home with their smart
                automation solutions. We can now control our entire house from
                our phones! Their team was professional and quick to implement
                the system."
              </p>

              <h5 className='text-white text-lg text-center leading-normal font-medium afacad'>
                Adeyemi Samson, Residential Customer
              </h5>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='w-[17.3125rem] h-[14.75rem] small:w-[18.3125rem] small:h-[13.75rem] md:w-[28.0625rem] lg:w-[41.0625rem] md:h-[16.875rem] mx-auto rounded-[1.25rem] px-[0.95rem] md:px-[3.25rem] lg:px-[6.25rem] pt-[2rem] lg:pt-[3rem] xl:pt-[3.31rem] backdrop-[15px] bg-[#0C5C93]/50 border-2 border-[#0C5C9380]'>
              <p className='text-white text-sm small:text-[15.5px] small:leading-[1.25rem] md:leading-[1.75rem] md:text-left text-center pb-[0.63rem] md:pb-[2.69rem] md:text-lg font-normal afacad '>
                "Nasmaj Hubs Services Ltd transformed our home with their smart
                automation solutions. We can now control our entire house from
                our phones! Their team was professional and quick to implement
                the system."
              </p>

              <h5 className='text-white text-lg text-center leading-normal font-medium afacad'>
                Fola Adams, Residential <br className='md:hidden' />
                Customer
              </h5>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
