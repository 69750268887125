import React, { useEffect } from 'react';
import Header from '../Header';
import Galleries from './Galleries';
import gallery from '../../../assets/Svgs/Gallery.svg';

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <section>
      <Header text='Gallery' background={gallery} />
      <Galleries />
    </section>
  );
};

export default Gallery;
