import React, { useRef } from 'react';
import alexa from '../../assets/Svgs/alexa.svg';
import amazon from '../../assets/Svgs/amazon.svg';
import cicc from '../../assets/Svgs/cicc.svg';
import cisco from '../../assets/Svgs/cisco.svg';
import hua from '../../assets/Svgs/hua.svg';
import jinko from '../../assets/Svgs/jinko.svg';
import kartel from '../../assets/Svgs/kartel.svg';
import matrix from '../../assets/Svgs/matrix.svg';
import matrixx from '../../assets/Svgs/matrixx.svg';
import Panasonic from '../../assets/Svgs/Panasonic.svg';
import sciener from '../../assets/Svgs/sciener.svg';
import tenda from '../../assets/Svgs/tenda.svg';
import trust from '../../assets/Svgs/trust.svg';
import yaoko from '../../assets/Svgs/yaoko.svg';
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame,
} from 'framer-motion';
import { wrap } from '@motionone/utils';

const ParallaxText = ({ children, baseVelocity = 100 }) => {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400,
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false,
  });

  // Change this to match the width of your items to avoid jumps
  const x = useTransform(baseX, (v) => `${wrap(-50, 0, v)}%`);

  const directionFactor = useRef(1);
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get();

    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className='parallax'>
      <motion.div className='scroller' style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
};
const Partners = () => {
  return (
    <section className='pt-[1.81rem] md:pt-[3.81rem] lg:pt-[6.81rem] '>
      <div className='flex flex-col md:flex-row px-2 md:justify-between mac:max-w-[83rem] mx-auto xl:max-w-[73rem] pro:max-w-[52rem] md:max-w-[45rem] max-w-[22rem]  lg:max-w-[59rem]'>
        <h2 className='md:text-[3rem] text-[2rem]  font-bold leading-[3rem] afacad text-[#002C54]'>
          Our Trusted
          <br className='hidden md:block' />
          Partners
        </h2>
        <p className='text-[#002C54] afacad md:w-[28rem] lg:w-[52rem] text-base md:text-[1rem] lg:text-[1.5rem] leading-normal font-normal'>
          At Nasmaj Hubs Services Ltd, we believe in building strong,
          long-lasting relationships with industry leaders who share our vision
          for innovation, sustainability, and quality. Our partnerships with
          these trusted companies and organizations allow us to deliver
          cutting-edge solutions to our clients across multiple sectors.
        </p>
      </div>
      <div className='py-[2.65rem]'>
        <div className='pb-5 md:pb-10'>
          <ParallaxText baseVelocity={-5}>
            <div className='flex gap-7 md:gap-[3rem] items-center h-16 md:h-20'>
              <img
                src={sciener}
                alt='logo'
                className='w-16 h-14 md:w-auto md:h-auto'
              />
              <img
                src={cisco}
                alt='logo'
                className='w-16 h-14 md:w-auto md:h-auto'
              />
              <img
                src={tenda}
                alt='logo'
                className='w-16 h-14 md:w-auto md:h-auto'
              />
              <img
                src={matrixx}
                alt='logo'
                className='w-16 h-14 md:w-auto md:h-auto'
              />
              <img
                src={jinko}
                alt='logo'
                className='w-16 h-14 md:w-auto md:h-auto'
              />
              <img
                src={Panasonic}
                alt='logo'
                className='w-16 h-14 md:w-auto md:h-auto'
              />
              <img
                src={alexa}
                alt='logo'
                className='w-16 h-14 md:w-auto md:h-auto'
              />
            </div>
          </ParallaxText>
        </div>

        <div className='pt-5 md:pt-10'>
          <ParallaxText baseVelocity={5}>
            <div className='flex gap-7 md:gap-[3rem] h-7 md:h-12'>
              <img src={kartel} alt='logo' />
              <img src={cicc} alt='logo' />
              <img src={amazon} alt='logo' />
              <img src={matrix} alt='logo' />
              <img src={hua} alt='logo' />
              <img src={yaoko} alt='logo' />
              <img src={trust} alt='logo' />
            </div>
          </ParallaxText>
        </div>
      </div>
    </section>
  );
};

export default Partners;
