import React from 'react';
import light from '../../assets/Svgs/light.svg';
import about from '../../assets/Svgs/about.svg';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <section className='bg-white mt-[1rem] md:mt-[5rem] h-[45rem] md:h-[27rem] lg:h-[29rem] xl:h-[31rem]'>
      <article className='flex flex-col gap-[3rem] px-2 md:flex-row md:justify-between mac:max-w-[83rem] mx-auto xl:max-w-[72rem] pro:max-w-[52rem] md:max-w-[45rem] max-w-[22rem]  lg:max-w-[59rem]'>
        <div className=''>
          <h2 className='leading-[5.625rem] font-bold text-[2rem] text-[#002C54] md:text-[3rem] afacad'>
            Who we are
          </h2>

          <p className='text-[#002C54] w-full text-base pb-[1.25rem] md:pb-[2.69rem] afacad md:w-[27rem] lg:w-[37rem] font-extralight md:text-xl lg:text-2xl leading-normal'>
            Nasmaj Hubs Services Ltd is a leading provider of integrated
            solutions in renewable energy, smart home automation, surveillance,
            construction, and general contract. With a commitment to delivering
            cutting-edge, sustainable, and secure living environments, we
            combine expertise across industries to transform the way homes and
            businesses operate.
          </p>

          <button className='bg-[#FFDF53] font-semibold md:text-base lg:text-lg leading-normal inter text-[#002C54] rounded-full py-[1rem] px-[2.5rem]'>
            <Link to='/about'> Read more</Link>
          </button>
        </div>
        <div className='relative'>
          <img src={about} alt='' className='w-auto md:h-[400px] lg:h-auto' />
          <div className=''>
            <img
              src={light}
              alt=''
              className='absolute top-44 w-28 h-28 -right-7 md:w-28 md:-right-2 md:top-56 xl:top-72 lg:-right-7 xl:-right-2 rotate-8 lg:w-56 lg:h-56 xl:w-56 xl:h-56'
            />
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
