import React, { useEffect } from 'react';
import Header from '../Header';
import Contacts from './Contacts';
import contacts from '../../../assets/Svgs/Contacts.svg';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <section>
      <Header text='Contact Us' background={contacts} />
      <Contacts />
    </section>
  );
};

export default Contact;
