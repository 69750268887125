import React from 'react';
import who from '../../../assets/Svgs/who.svg';
import who2 from '../../../assets/Svgs/Who2.svg';
import light2 from '../../../assets/Svgs/light2.svg';

const Who = () => {
  return (
    <section className=' mac:max-w-[83rem] mx-auto xl:max-w-[72rem] px-2 small:px-0 pro:max-w-[52rem] md:max-w-[45rem] max-w-[22rem]  lg:max-w-[59rem]'>
      <article className=''>
        <div className='mt-[4.2rem] md:mt-[3rem] lg:mt-[4.94rem] xl:mt-[6.94rem]'>
          <h1 className='text-[#002C54] afacad text-[2rem] md:text-[3rem] leading-normal md:leading-[5.625rem] font-bold'>
            Who we are
          </h1>
          <p className='w-full md:w-[23.75rem] lg:w-[30.75rem] xl:w-[36.75rem] text-[#002C54] leading-normal font-normal afacad text-base lg:text-lg xl:text-[1.25rem]'>
            At Nasmaj Hubs Services Ltd, we are dedicated to providing
            innovative, sustainable, and smart solutions across multiple
            sectors. With expertise in renewable solar energy, smart home
            automation, security systems, building maintenance, and
            construction, we help our clients create safer, smarter, and more
            energy-efficient environments. Founded with the mission to drive
            change through technology and sustainability, Nasmaj Hubs Services
            Ltd brings together a team of skilled professionals committed to
            delivering excellence in every project.{' '}
          </p>
        </div>
        <div className='xl:-mt-64  md:-mt-60 md:ml-20 mt-7 lg:-mt-60 ml-16 xl:ml-10'>
          <img
            src={who}
            alt='who'
            className='xl:w-auto hidden md:flex xl:h-auto w-[20rem] h-[17.8395rem] md:w-[40rem] lg:w-[55rem] md:h-[35rem]'
          />
          <img
            src={who2}
            alt='who'
            className=' w-auto mx-auto h-auto flex  md:hidden'
          />
        </div>
      </article>
      <div className='flex justify-end -mt-14 items-end md:-mt-64'>
        <img
          src={light2}
          alt=''
          className='xl:w-auto md:w-[15rem] w-24 h-20 md:h-[15rem] lg:w-[20rem] lg:h-[20rem] xl:h-auto'
        />
      </div>
    </section>
  );
};

export default Who;
