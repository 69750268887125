import React from 'react';
import nasmaj from '../../assets/Svgs/nasmaj.svg';

const Why = () => {
  const why = [
    {
      id: 1,
      title: 'Expertise Across Industries',
      num: '01',
      description:
        'Our diverse team of professionals brings together decades of experience in solar energy, security, smart technology, and construction',
    },
    {
      id: 2,
      title: 'Innovative Solutions',
      num: '02',
      description:
        'We are always at the forefront of technological advancements, offering cutting-edge solutions that meet the evolving needs of modern living and business environments.',
    },
    {
      id: 3,
      title: 'Sustainability Commitment',
      num: '03',
      description:
        'We are passionate about helping our clients reduce their carbon footprint by implementing green technologies such as solar energy and energy-efficient systems.',
    },
    {
      id: 4,
      title: 'Customer-First Approach ',
      num: '04',
      description:
        'We tailor our services to meet the unique requirements of each client, ensuring that we provide personalized and impactful solutions.',
    },
    {
      id: 5,
      title: 'Quality Assurance',
      num: '05',
      description:
        'From the materials we use to the teams we deploy, quality is the hallmark of everything we do.',
    },
  ];
  return (
    <section className='mac:max-w-[83rem] xl:max-w-[73rem] pro:max-w-[52rem] px-2 small:px-0 md:max-w-[45rem] max-w-[22rem] lg:max-w-[59rem] md:gap-[1.5rem] xl:gap-0 flex md:flex-row flex-col-reverse gap-[1.8rem] items-center justify-between xl:justify-around mx-auto'>
      <img
        src={nasmaj}
        alt='nasmaj'
        className='xl:w-auto md:w-[20rem] h-[35rem] lg:w-[29.1875rem] lg:h-[45rem] xl:h-auto'
      />
      <article className=''>
        <h2 className='text-[#002C54] afacad pb-[2rem] xl:pb-[3rem] text-[2rem] md:text-[2.7rem] xl:text-[3rem] font-bold leading-[3.625rem]'>
          Why Nasmaj?
        </h2>
        <div>
          {why.map((items) => (
            <div
              key={items.id}
              className='flex items-start gap-[2rem] lg:w-[28.625rem] xl:w-[30.625rem]'
            >
              <h3 className='text-[#FFDF53] afacad text-[2.25rem] md:text-[1.715rem] lg:text-[2.125rem] xl:text-[3.125rem] -mt-3 font-bold leading-normal'>
                {items.num}
              </h3>
              <div className=''>
                <h4 className='text-[#002C54] pb-[0.3rem] afacad font-bold text-sm md:text-xs lg:text-lg xl:text-[1.25rem] leading-normal uppercase'>
                  {items.title}
                </h4>
                <p className='text-[#002C54] afacad pb-[1.12rem] md:pb-[0.75rem] xl:pb-[2.5rem] text-sm md:text-xs lg:text-lg xl:text-[1.25rem] leading-normal font-normal'>
                  {items.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className='md:px-20 hidden md:flex'>
          <button className='w-full md:px-[2.5rem] mt-[1rem] xl:mt-[2rem] inter text-base xl:text-[1.125rem] font-semibold leading-[1rem] py-[1rem] xl:py-[1.12rem] bg-[#FFDF53] text-[#002C54] rounded-full'>
            View all
          </button>
        </div>
      </article>
    </section>
  );
};

export default Why;
