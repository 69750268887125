import React from 'react';
import team from '../../../assets/Svgs/team.svg';
import innovate from '../../../assets/Svgs/innovate.svg';
import customer from '../../../assets/Svgs/customer.svg';

const Why = () => {
  const why = [
    {
      id: 1,
      title: 'Expert team',
      icon: team,
      content:
        'Our team brings decades of experience in renewable energy, smart home automation, security systems, and construction. We are experts in delivering customized solutions tailored to meet your needs.',
    },
    {
      id: 2,
      title: 'Innovative Technologies',
      icon: innovate,
      content:
        'We leverage the latest technologies to provide cutting-edge services. From advanced solar power systems to smart home automation, we ensure our clients benefit from modern, efficient solutions.',
    },
    {
      id: 3,
      title: 'Customer-Centric Approach',
      icon: customer,
      content:
        'The client always comes first. We listen, understand, and deliver solutions that not only meet but exceed your expectations.',
    },
  ];
  return (
    <section className='mac:max-w-[83rem] xl:mt-0 mt-[1.5rem] lg:max-w-[59rem] flex flex-col gap-[3rem] md:gap-0 md:flex-row md:justify-between lg:justify-around mx-auto xl:max-w-[72rem] small:px-0 px-2 small:max-w-[22rem] pro:max-w-[52rem] md:max-w-[45rem]'>
      <div>
        <h2 className='text-[#002C54] afacad text-[2rem] lg:text-[3rem] leading-normal md:leading-[5.625rem] font-bold'>
          Why choose us
        </h2>

        <p className='text-[#002C54] w-full md:w-[19rem] lg:w-[28.375rem] xl:w-[36.375rem] afacad lg:text-[17px] text-base xl:text-[1.25rem] leading-normal font-normal'>
          At Nasmaj Hubs Services Ltd, we pride ourselves on delivering
          reliable, innovative, and customer-focused solutions.
          <br /> <br />
          Our core values of innovation, integrity, and customer-centricity
          guide everything we do. We partner with industry leaders and utilize
          cutting-edge technologies to ensure our clients stay ahead in a
          rapidly evolving world. <br /> <br />
          From residential homes to commercial projects, Nasmaj Hubs Services
          Ltd is your trusted partner in building a sustainable and secure
          future.
        </p>
      </div>
      <div className='space-y-[1.25rem]'>
        {why.map((item) => (
          <div
            key={item.id}
            className='w-full md:w-[23.1875rem] lg:w-[29.1875rem] flex items-center px-[0.9rem] md:justify-start md:items-start lg:h-[9rem] xl:h-[9.375rem] md:px-[1rem] lg:px-[1.81rem] gap-3 py-[1rem] rounded-[0.625rem] bg-white shadow-lg'
          >
            <img
              src={item.icon}
              alt=''
              className='lg:w-[3.3125rem] lg:h-[3.3125rem] md:w-[2.3125rem] md:h-[2.3125rem]'
            />

            <div>
              <h3 className='font-bold leading-normal afacad text-xl md:text-sm lg:text-lg xl:text-[1.25rem] text-[#002C54]'>
                {item.title}
              </h3>

              <p className='text-[#002C54] font-normal leading-normal pt-[0.5rem] md:pt-0 text-sm md:text-sm lg:text-[15px] xl:text-base afacad'>
                {item.content}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Why;
