import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import bg1 from '../../assets/Images/bg1.jpg';
import bg2 from '../../assets/Images/bg2.jpg';
import bg3 from '../../assets/Images/bg3.jpg';
import bg4 from '../../assets/Images/bg4.jpg';
import bg5 from '../../assets/Images/bg5.jpg';
import bg6 from '../../assets/Images/bg6.jpg';
import hero3 from '../../assets/Svgs/hero3.svg';
import { Link } from 'react-router-dom';

const Hero = () => {
  const slides = [
    {
      img: bg1,
      img2: bg4,
      alt: 'bg1',
      title: 'Innovative Solutions for a Smart and Safe Future',
      description:
        'Your partner in renewable energy, smart automation, and sustainable construction.',
    },
    {
      img: bg2,
      img2: bg5,

      alt: 'bg2',
      title: 'Innovative Solutions for a Smart and Safe Future',
      description:
        'Your partner in renewable energy, smart automation, and sustainable construction.',
    },
    {
      img: bg3,
      img2: bg6,

      alt: 'bg3',
      title: 'Innovative Solutions for a Smart and Safe Future',
      description:
        'Your partner in renewable energy, smart automation, and sustainable construction.',
    },
  ];

  return (
    <section className='relative pt-16 lg:pt-0'>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop={true} // Enable looping
        className='h-[20.1875rem] md:h-[30rem] lg:h-[38rem] xl:h-[45rem]'
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <figure className='block'>
              <img
                src={slide.img}
                alt={slide.alt}
                className='object-cover hidden md:block w-full h-full'
              />
              <img
                src={slide.img2}
                alt={slide.alt}
                className='object-cover md:hidden block w-full h-full'
              />
            </figure>
            <div className='w-[17.375rem] h-[12.125rem] small:w-[20.375rem] small:h-[13.875rem] md:w-[32.4375rem] md:h-[19.125rem] lg:w-[39.4375rem] lg:h-[22.125rem] xl:w-[49.4375rem] mx-7 md:mx-16 absolute inset-0 xl:h-[25.125rem] border-2 border-white/20 rounded-lg bg-white/30 backdrop-blur-[15px] mt-auto mb-3 md:mt-20 lg:mt-36 xl:mt-44 p-3 small:p-4 md:p-8 xl:p-12'>
              <h1 className='small:text-2xl text-lg md:text-[1.8125rem] lg:text-[2.8125rem] xl:text-[3.8125rem] afacad font-bold text-[#002C54] leading-normal lg:leading-[3.8125rem]'>
                {slide.title}
              </h1>
              <p className='leading-normal w-[18rem] md:w-[24rem] lg:w-[32rem] md:leading-[1.7rem] text-sm pt-[0.75rem] small:pt-[1rem] md:pt-[1.2rem] xl:pt-[1.44rem] md:text-lg pb-[1rem] small:pb-[1.5rem] md:pb-[2rem] font-semibold afacad lg:text-[1.5rem] text-[#002C54]'>
                {slide.description}
              </p>
              <Link to='/services'>
                <button className='bg-[#FFDF53] text-xs small:text-sm font-semibold md:text-base lg:text-lg leading-normal inter text-[#002C54] rounded-full py-3 md:py-[1rem] lg:py-[1.12rem] px-[2.5rem]'>
                  Explore Our Services
                </button>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Hero;
