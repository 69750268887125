import React from 'react';
import ser1 from '../../assets/Images/ser1.jpg';
import ser2 from '../../assets/Images/ser2.jpg';
import ser3 from '../../assets/Images/ser3.jpg';
import ser4 from '../../assets/Images/ser4.jpg';
import ser5 from '../../assets/Images/ser5.jpg';
import ser6 from '../../assets/Images/ser6.jpg';
import ser7 from '../../assets/Images/ser7.jpg';
import ser8 from '../../assets/Images/ser8.jpg';
import { Link } from 'react-router-dom';

const Services = () => {
  const services = [
    {
      id: 1,
      title: '<span class="text-[#017DC0]">Renewable</span> Solar Solutions',
      content:
        'At Nasmaj Hubs Services Ltd, we specialize in providing clean, sustainable solar energy solutions to homes and businesses. Our solar panel installations are designed to maximize energy efficiency, reduce costs, and lower environmental impact. Whether its a small-scale residential setup or a large commercial installation, we bring innovation and expertise to every project.',
      button: 'Contact us',
      img: ser1,
    },
    {
      id: 2,
      title: '<span class="text-[#017DC0]">Smart Home</span> & Automation',
      content:
        'Imagine a home that works for you. Our smart home solutions make your space more convenient, secure, and energy-efficient. With integrated control systems for lighting, climate, security, and entertainment, we turn your house into a smart hub that can be controlled via smartphone, tablet, or voice command.',
      button: 'Contact us',
      img: ser2,
    },
    {
      id: 3,
      title:
        '<span class="text-[#017DC0]">Building Maintenance</span> & Construction',
      content:
        'Nasmaj Hubs Services Ltd offers a wide range of building maintenance and construction services, from small repairs to full-scale construction projects. Whether you are upgrading an existing structure or building from the ground up, our team delivers high-quality results on time and within budget.',
      button: 'Contact us',
      img: ser3,
    },
    {
      id: 4,
      title:
        '<span class="text-[#017DC0]">Surveillance</span> & Security Solutions',
      content:
        'Ensuring the safety of your property and loved ones is a top priority. Nasmaj Hubs Services Ltd offers state-of-the-art surveillance and security systems tailored to your specific needs. From residential homes to large business premises, we provide CCTV, alarm systems, and integrated security solutions to give you 24/7 peace of mind.',
      button: 'Contact us',
      img: ser4,
    },
    {
      id: 5,
      title: '<span class="text-[#017DC0]">Fire Alarm</span> System',
      content:
        'Nasmaj Hubs Services Ltd offers comprehensive fire alarm system design, installation, and maintenance services. Our systems are equipped with cutting-edge technology to provide early detection and rapid response, ensuring that your building complies with all safety regulations and provides maximum protection in the event of a fire.',
      button: 'Contact us',
      img: ser5,
    },
    {
      id: 6,
      title: '<span class="text-[#017DC0]">Network</span> Infrastructure',
      content:
        'In today’s digital world, robust network infrastructure is the backbone of any successful business. Nasmaj Hubs Services Ltd provides end-to-end network infrastructure solutions, including structured cabling, network design, installation, and maintenance services. Our team ensures that your network is optimized for speed, security, and reliability, supporting everything from small offices to large enterprise environments.',
      button: 'Contact us',
      img: ser6,
    },
    {
      id: 7,
      title: '<span class="text-[#017DC0]">Street Lighting</span> Installation',
      content:
        'We deliver energy-efficient and sustainable street lighting solutions for municipalities, residential complexes, and commercial properties. Our street lighting installations utilize advanced LED technology to reduce energy consumption and enhance visibility and safety. Whether for urban streets or suburban developments, our lighting solutions are tailored to meet the specific needs of each project.',
      button: 'Contact us',
      img: ser7,
    },
    {
      id: 8,
      title: '<span class="text-[#017DC0]">General</span> Contract',
      content:
        'As a full-service general contractor, Nasmaj Hubs Services Ltd oversees every aspect of your project from start to finish. Our team manages all phases of construction and renovation, ensuring that every detail aligns with your vision and specifications. We are committed to delivering exceptional craftsmanship and client satisfaction.',
      button: 'Contact us',
      img: ser8,
    },
  ];

  return (
    <section className='py-[0rem] md:py-[2.58rem] mb-7 md:mb-0 lg:h-[230rem] xl:h-[265rem] bg-white'>
      <div className=' px-2 mac:max-w-[83rem] mx-auto xl:max-w-[73rem] pro:max-w-[52rem] md:max-w-[45rem] max-w-[22rem] lg:max-w-[59rem]'>
        <h1 className='text-[#002C54] leading-normal text-center text-[2rem] md:text-[3rem] font-bold lg:leading-[5.625rem] afacad'>
          Core Services
        </h1>

        {/* <div className='mt-[1.78rem] lg:mt-[3.25rem] flex gap-[7rem] md:gap-[3rem] md:flex-row flex-col md:justify-between h-[100rem] small:h-[103rem] md:h-[25rem] lg:h-[35rem]'>
          {services.map((item, index) => (
            <div key={item.id} className='relative'>
              <img
                src={item.img}
                alt=''
                className={`${index === 1 ? 'mt-[17rem] md:mt-0' : ''} ${
                  index === 2 ? 'mt-[17rem] md:mt-0' : ''
                }`}
              />
              <div
                className={`h-[22.46656rem] ${
                  index === 1 ? 'top-[26rem] small:top-[27rem] md:mt-0' : ''
                } ${
                  index === 2 ? 'top-[26rem] small:top-[27rem] md:mt-0' : ''
                } w-[18.04613rem] small:w-[21.04613rem] md:w-[14.3125rem] md:h-[20.875rem] lg:w-[18.3125rem] lg:h-[28.875rem] xl:w-[22.3125rem] xl:h-[24.875rem] absolute left-0 right-0 mx-auto top-36 small:top-40 md:top-24 lg:top-36 xl:top-44 bg-white shadow-lg px-1 lg:px-2 rounded-tl-[6.25rem] rounded-tr-[6.25rem] rounded-bl-lg rounded-br-lg border-2 border-[#8D8A8A] py-7`}
              >
                <div className='flex flex-col justify-center'>
                  <div>
                    <img
                      src={item.icon}
                      alt='icons'
                      className='xl:w-auto xl:h-auto w-16 h-16 md:w-10 md:h-10 lg:w-16 l:h-16 mx-auto'
                    />
                  </div>
                </div>
                <p
                  className='text-center pt-[0.94rem] pb-[0.88rem] text-xl small:text-2xl md:text-lg text-[#002C54] afacad font-mediumS md:leading-normal lg:text-[1.5rem] xl:text-[1.625rem]'
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <p
                  className='text-[#002C54] px-3 afacad text-base small:text-lg md:text-sm lg:text-[1.1875rem] font-medium md:leading-normal'
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
            </div>
          ))}
        </div>

        <div className='flex justify-center'>
          <button className='w-full md:w-auto md:px-[2.5rem] mt-[5rem] lg:mt-[8rem] inter text-[1.125rem] font-semibold leading-[1rem] py-[1.12rem] bg-[#FFDF53] text-[#002C54] rounded-full'>
            <Link to='/services'>See all Services</Link>
          </button>
        </div>*/}

        <div className='mt-[0.81rem] md:mt-[2.51rem] lg:mt-[6.51rem] xl:mt-[4.51rem] space-y-[4.5rem] md:space-y-[4.98rem] lg:space-y-[6.38rem] xl:space-y-[9.38rem] '>
          {services.map((serv, index) => (
            <article
              className={`flex flex-col gap-[2rem] md:flex-row md:justify-around ${
                index % 2 !== 0 ? 'md:flex-row-reverse md:justify-evenly' : ''
              }`}
            >
              <div className='lg:w-[20.375rem] xl:w-[30.375rem]'>
                <h5
                  className={`pb-[0.75rem] md:pb-[1rem] text-[#002C54] afacad leading-normal font-medium text-[1.375rem] md:text-base lg:text-[1.5rem] xl:text-[1.625rem] border-b-2 border-[#00B5E8] lg:w-[37.09375rem] xl:w-[48.09375rem]`}
                  dangerouslySetInnerHTML={{ __html: serv.title }}
                />

                <p className='md:w-[18.375rem] lg:w-[23.375rem] xl:w-[30.375rem] pt-[1.25rem] lg:pt-[2.65rem] text-[#002C54] afacad font-normal leading-normal md:text-sm lg:text-base xl:text-[1.1875rem]'>
                  {serv.content}
                </p>
                <Link to='/contact'>
                  <button className='px-[2.3rem] hidden md:flex mt-[1.59rem] py-[0.65rem] text-[#002C54] text-lg lg:text-[1.5rem] leading-normal font-semibold afacad bg-[#FFDF53] rounded-full'>
                    {serv.button}
                  </button>

                  <p className='mt-[1.29rem] afacad md:hidden font-medium leading-normal underline text-[#00AAFA] text-base'>
                    Get Started
                  </p>
                </Link>
              </div>
              <img
                className='mac:w-[35rem] xl:h-auto bg-[#E4F9FF] md:h-[16rem] lg:h-[21rem] md:w-[21rem] xl:w-[37rem] lg:w-[27rem] lg:-mt-5 xl:-mt-0'
                src={serv.img}
                alt={serv.title}
              />
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
