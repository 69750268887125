import React, { useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Services from './Services/Services';
import Why from './Why/Why';
import Testimonials from './Testimonials/Testimonials';
import Partners from './Partners/Partners';
import Footer from './Footer/Footer';
import Nav from './Nav/Nav';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div>
      <Nav />
      <Hero />
      <About />
      <Services />
      <Why />
      <Testimonials />
      <Partners />
      <Footer />
    </div>
  );
};

export default Home;
