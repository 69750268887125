import React, { forwardRef } from 'react';

const InputField = forwardRef(
  (
    { label, type = 'text', name, value, placeholder, onChange, error },
    ref
  ) => {
    return (
      <div className='mb-4 flex flex-col gap-2'>
        <label
          htmlFor={name}
          className='text-[#002C54] text-[1.25rem] leading-[1.90494rem] md:text-lg lg:text-[1.5rem] font-normal afacad'
        >
          {label}
        </label>
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          ref={ref}
          // placeholder={placeholder}
          className={`w-full h-[3.575rem] pl-5 md:w-[20.0125rem] lg:w-[26.5125rem] xl:w-[30.8125rem] mac:w-[34.8125rem] md:h-[3.5125rem] lg:h-[4.125rem] border-[0.8px] border-[#002C54] rounded-[0.625rem] shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg`}
        />
        {error && <p className='mt-1 text-sm text-red-500'>{error}</p>}
      </div>
    );
  }
);

export default InputField;
